import { createGlobalStyle } from 'styled-components'
import { Normalize } from './normalize'
import { Reset } from './reset'
// import { FontFace } from './fontFace'
// import * as font from '../fonts'
export const GlobalStyles = createGlobalStyle`

    ${Normalize} 
    ${Reset} 

    html {
        font-size: 14px; 
        scroll-behavior: smooth !important;
    }
 
    body {
    font-family: 'Roboto','Helvetica Neue','Helvetica','Arial', sans-serif;
    font-weight: 400;
    line-height: 2rem;

    min-height: 100vh;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';

    background-color: white;
    scroll-behavior: smooth;
    overscroll-behavior: none;

    }

    a, span, button, div {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    #___gatsby {
        background-color: #fff;
        z-index: 1;
    }
    #gatsby-focus-wrapper {
        z-index:1;
    }
`
