import { css } from 'styled-components'

export const Reset = css`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    font-size: 100%;
    box-sizing: border-box;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ol,
  ul {
    list-style: none;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`
