module.exports = {
  hr: {
    featuredCustomer: 'Priče korisnika',
    readCustomerStory: 'Otkrij priču korisnika',
    loadMore: 'Izlistaj više',
    yourEmailPlaceholder: 'Vaša email adresa',
    yourEmail: 'E-mail',
    yourFullName: 'Ime i prezime',
    yourMessage: 'Tvoja poruka',
    contactSubmit: 'Pošalji poruku',
    contactReset: 'Obriši',
    yourIndustry: 'Odaberite...',
    follow: 'Pratite nas na',
    footerMsg: 'Prijavi se na Rentlio newsletter i primaj mjesečne savjete za uspjeh u turizmu!',
    tourismTips: 'Prijavi me',
    currency: 'HRK',
    for: 'Za',
    priceMsg: 'smještajnih jedinica pretplata iznosi ',
    checkboxCM: 'Trebam Channel Manager',
    tryFree: 'Isprobaj besplatno',
    monthlyPrice: 'Mjesečna cijena po jedinici je',
    perMonth: 'mjesečno',
    vatExcluded: 'PDV nije uključen u cijenu.',
    perUnit: ' po jedinici',
    finalPriceMsg:
      'Za 15+ smještajnih jedinica pošaljite e-mail na <a href="mailto:hello@rentl.io">hello@rentl.io</a> i zatražite ponudu.',
    replyMsg: 'Odgovorit ćemo vam u najkraćem mogućem roku, najčešće unutar jednog radnog dana.',
    accommodation: '*smještajna jedinica je svaki apartman, soba ili krevet (u slučaju hostela) koji iznajmljujete.',
    readMoreStory: 'Pročitaj više!',
    mostRead: 'Najčitanije',
    startHere: 'Započni ovdje',
    exploreCase: 'Istraži novosti o proizvodu, priče korisnika i savjete u turizmu',
    findOutMore: 'Saznaj više',
    storyCarouselTitle: 'Ne oslanjajte se samo na našu riječ',
    searchBlog: 'Pretraži Blog',
    explore: 'Saznaj više',
    latestArticles: 'Najnoviji Članci',
    benefits: {
      free: '10 dana besplatnog korištenja',
      easy: 'Lako postavljanje',
      cancel: 'Otkaži bilo kada, bez ugovora',
      card: 'Kreditna kartica nije potrebna'
    },
    pricingBenefits: {
      easy: 'Lako postavljanje',
      card: 'Kreditna kartica nije potrebna',
      cancel: 'Otkaži bilo kada, bez ugovora',
      fees: 'Bez rezervacija / dodatnih troškova'
    },
    vacationRentals: 'Mali iznajmljivači',
    propertyManagers: 'Property manageri',
    other: 'Ostali',
    storyQuote: 'Svi smo u ovom zajedno',
    checkJob: 'Trenutne prilike',
    teamCulture: 'Saznaj više o kulturi tima',
    nps: 'Saznaj više o NPS-u',
    privacyPolicy: 'Izjava o privatnosti',
    termsCondition: 'Uvjeti korištenja',
    customerSuccessTitle: 'Otkrij kako su tvoji kolege i kolegice iz industrije transformirali način na koji rade!',
    customerSuccessSubtitle: 'Priče korisnika',
    category: 'Kategorija',
    jobApply: 'Prijavi se',
    jobApplyMessage: 'Pošalji svoju otvorenu molbu',
    jobApplyMessageCareer: 'Open application',
    newsLetterTitle: 'Primaj besplatne savjete izravno u svoj inbox i postani bolji manager u turizmu!',
    newsLetterSubtitle: 'Svaki mjesec šaljemo ti korisne članke vezane za turističku industriju!',
    newsLetterNext: 'Dalje',
    newsLetterSubscribe: 'Pretplati me',
    newsLetterThankYou: 'Vaša prijava je bila uspješna.',
    automatisation: {
      email: 'Automatizirani e-mailovi',
      creditCard: 'Automatska validacija kreditnih kartica',
      claim: 'Zahtjevi za rezervaciju - upozorit će vas prije nego isteknu',
      price: 'Dinamičko određivanje cijena zahvaljujući integraciji s Pricelabsom',
      rooms: 'Automatska dodjela soba'
    },
    cookiesPolicy: 'Pravilima o korištenju kolačića',
    cookiesContent:
      'Koristimo kolačiće radi pružanja najboljeg korisničkog iskustva. Pregledavanjem i korištenjem naše internet stranice slažete se s korištenjem kolačića u skladu s našim',
    cookiesButton: 'Slažem se',
    legalInfo:
      'Rentlio d.o.o., Narodni trg 2 (sjedište), 23000 Zadar, upisano u Sudski registar Trgovačkog suda u Zadru (rješenje: Tt-14/2279-2, MBS: 110046995), temeljni kapital u iznosu od 20.000,00 kuna uplaćen ucijelosti; OIB: 27842617105; Društvo zastupa Marko Mišulić, jedini član Uprave; Račun otvoren u Zagrebačkoj banci d.d. Zagreb (Trg bana J. Jelačića 10), IBAN: HR3423600001102468875.',
    checkPrivacy: 'Pogledaj',
    messageSent: 'Poruka poslana!',
    exploreRentlio: 'Istraži Rentlio',
    similarArticles: 'Uživaj u ostalim člancima',
    newsLetterCTA: 'Istraži što ti sve nudi Rentlio!',
    exploreRentlio: 'Saznaj više',
    all: 'Svi',
    livePreviews: 'Demo primjeri',
    livePreview: 'Demo primjer',
    forFreeNote: 'Koristite besplatno do 1. ožujka 2021.',
    bookinEngineCost: 'Booking Engine naplaćuje se zasebno.',
    search: 'Pretraži',
    jobPosition: 'Pozicija popunjena',
    jobPositionInProcess: 'Odabir kandidata u tijeku'
  },

  en: {
    featuredCustomer: 'Featured customer',
    readCustomerStory: 'Read customer story',
    loadMore: 'Load more',
    yourEmailPlaceholder: 'Your email address',
    yourEmail: 'E-mail',
    yourFullName: 'Full name',
    yourMessage: 'Your message',
    contactSubmit: 'Get in touch',
    contactReset: 'Reset',
    yourIndustry: 'Choose...',
    follow: 'Follow us on',
    footerMsg:
      'Insights and advice about the tourism industry that will help you grow your business - in your inbox every month!',
    tourismTips: 'Get tourism tips',
    currency: 'EUR',
    for: 'For',
    priceMsg: "accommodation units, you'll pay",
    checkboxCM: 'I NEED A CHANNEL MANAGER',
    tryFree: 'Try for free',
    monthlyPrice: 'Montly price per unit is',
    perMonth: 'per month',
    vatExcluded: 'Prices are VAT excluded.',
    perUnit: ' per unit',
    finalPriceMsg:
      'For 15+ accommodation units e-mail us at <a href="mailto: hello@rentl.io">hello@rentl.io</a> and ask for an offer.',
    replyMsg: 'We will reply as soon as possible. Usually within one business day.',
    accommodation:
      '*an accommodation unit is every apartment, room or bed (in case of hostels) that you rent out separately.',
    readMoreStory: 'Read Customer Story',
    mostRead: 'Most read',
    startHere: 'Start here',
    exploreCase: 'Explore product updates, case studies, and tourism tips',
    findOutMore: 'Read more insights from our team',
    storyCarouselTitle: "Don't take our word for it",
    searchBlog: 'Search Blog',
    explore: 'Explore',
    latestArticles: 'Latest Articles',
    benefits: {
      free: 'Free 10-day trial ',
      easy: 'Easy setup',
      cancel: 'Cancel any time, no contracts',
      card: 'No credit card required'
    },
    pricingBenefits: {
      easy: 'Easy setup',
      card: 'No credit card required',
      cancel: 'Cancel anytime - no contracts',
      fees: 'No reservation / hidden fees'
    },
    vacationRentals: 'Vacation rentals',
    propertyManagers: 'Property managers',
    other: 'Other',
    storyQuote: 'We are all in this together',
    checkJob: 'Current opportunities',
    teamCulture: 'Learn about our team culture',
    nps: 'Find out more about NPS',
    privacyPolicy: 'Privacy Policy',
    termsCondition: 'Terms and Conditions',
    customerSuccessTitle: 'Learn how your industry colleagues transformed the way they work!',
    customerSuccessSubtitle: 'Customer Success',
    category: 'Category',
    jobApply: 'Apply',
    jobApplyMessage: 'Send us your open job letter',
    jobApplyMessageCareer: 'Open application',

    newsLetterTitle: 'Get our tips straight to your inbox and become a better tourism manager!',
    newsLetterSubtitle: 'Insights, advice and analysis about the tourism industry emailed to you every month!',
    newsLetterNext: 'Next',
    newsLetterSubscribe: 'Subscribe',
    newsLetterThankYou: 'Thank your for your subscription.',
    automatisation: {
      email: 'Automated e-mails',
      creditCard: 'Automated credit card validation',
      claim: 'Optional reservations that warn you before they expire',
      price: 'Dynamic pricing thanks to Pricelabs integration',
      rooms: 'Automatic room assignment'
    },
    cookiesPolicy: 'Cookies policy',
    cookiesContent:
      'We use cookies to give you the best online experience. By using our website you agree to use our cookies in accordance with our',
    cookiesButton: 'Got it',
    legalInfo:
      'Rentlio d.o.o., Narodni trg 2 (headquarters), 23000 Zadar, registered with Zadar Commercial Court (Tt-14/2279-2, MBS:110046995), the share capital of 20.000,00hrk paid in full. VAT: HR27842617105; A person authorized to represent the company and the only board member: Marko Mišulić; Bank Account: Zagrebačka banka d.d. Zagreb (Trg bana J. Jelačića 10), IBAN: HR3423600001102468875',
    checkPrivacy: 'Check',
    messageSent: 'Message sent!',
    exploreRentlio: 'Explore Rentlio',
    similarArticles: 'You might also enjoy',
    newsLetterCTA: 'Supercharge your property management!',
    exploreRentlio: 'Explore Rentlio',
    all: 'All',
    livePreviews: 'Live Previews',
    livePreview: 'Live Preview',
    forFreeNote: 'Use for free until March 1st, 2021',
    bookinEngineCost: 'Booking Engine is charged separately.',
    search: 'Search',
    jobPosition: 'Position filled',
    jobPositionInProcess: 'Selection in process'
  }
}
