import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from 'styles/globalStyles'

import theme from '../../styles/theme'
import translations from '../../constants/translations'

const LanguageContext = createContext()
const LocaleProvider = LanguageContext.Provider
const LocaleConsumer = LanguageContext.Consumer

const Layout = ({ children, locale }) => {
  const i18n = translations[locale]
  return (
    <LocaleProvider value={{ i18n }}>
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <GlobalStyles />
          {children}
        </CookiesProvider>
      </ThemeProvider>
    </LocaleProvider>
  )
}

export { LanguageContext, LocaleConsumer }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string
}

export default Layout
