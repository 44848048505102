import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({
  description,
  lang,
  meta,
  image: metaImage,
  title,
  pathname,
  facebook,
  twitter,
  article,
  category,
  keywords,
  location,
  schemaMarkup
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const socialImage =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  const socialContent = {
    facebookTitle: facebook ? facebook.yoast_wpseo_facebook_title : null,
    facebookDescription: facebook ? facebook.yoast_wpseo_facebook_description : null,
    twitterTitle: twitter ? twitter.yoast_wpseo_twitter_title : null,
    twitterDescription: twitter ? twitter.yoast_wpseo_twitter_description : null
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: lang
      }}
      title={title}
      titleTemplate={`${category ? `%s | ${site.siteMetadata.title}` : title}`}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical
              },
              {
                rel: 'alternate',
                hrefLang: lang,
                href: `${site.siteMetadata.siteUrl}${pathname}`
              },
              {
                rel: 'preconnect',
                href: 'https://snap.licdn.com'
              },
              {
                rel: 'preconnect',
                href: 'https://www.google-analytics.com'
              }
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: socialContent.facebookTitle || title
        },
        {
          property: `og:description`,
          content: socialContent.facebookDescription || metaDescription
        },
        {
          property: `og:type`,
          content: `${article ? 'article' : 'website'}`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: socialContent.twitterTitle || title
        },
        {
          name: `twitter:description`,
          content: socialContent.twitterDescription || metaDescription
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(
          metaImage
            ? [
                {
                  property: 'og:image',
                  content: socialImage
                },
                {
                  property: 'og:image:width',
                  content: metaImage.width
                },
                {
                  property: 'og:image:height',
                  content: metaImage.height
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image'
                }
              ]
            : [
                {
                  property: 'og:image',
                  content: socialImage
                },
                {
                  name: 'twitter:card',
                  content: 'summary'
                }
              ]
        )
        .concat(meta)}
    >
      {schemaMarkup && <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `hr`,
  meta: [],
  keywords: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired
  }),
  keywords: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string
}

export default SEO
